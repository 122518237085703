import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useLanguage } from "../../LanguageContext";
import HeadShotComponent from "../../components/HeadShotComponent/HeadShotComponent";
import "./HomePage.css";

export default function Home() {
  const { language } = useLanguage();
  const [currentIdx, setCurrentIdx] = useState(0);

  const adjectives = [
    {
      lang: "en",
      items: [
        "FULL STACK SOFTWARE ENGINEER",
        "RETIRED DISC JOCKEY",
        "HACK 4 LA VOLUNTEER",
        "AMATEUR ICE CREAM TASTER",
      ],
    },
    {
      lang: "es",
      items: [
        "Ingeniero de software de pila completa",
        "Jubilado DJ",
        "Voluntario en Hack for LA",
        "Catador de helados aficionado",
      ],
    },
    {
      lang: "vi",
      items: [
        "Kỹ sư phần mềm Full Stack",
        "Đã nghỉ hưu DJ",
        "Tình nguyện tại Hack For LA",
        "Người nếm kem nghiệp dư",
      ],
    },
  ];

  const words = useMemo(() => {
    const currentLanguage = adjectives.find((adj) => adj.lang === language);
    return currentLanguage ? currentLanguage.items : [];
  }, [language, adjectives]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIdx((currentIdx) => (currentIdx + 1) % words.length);
    }, 2500);
    return () => clearInterval(intervalId);
  }, [words.length]);

  function renderContext() {
    switch (language) {
      case "en":
        return (
          <>
            <section className="left-info">
              <h1>RICHARD MUNDY III</h1>
              {words.map((w, idx) => (
                <p
                  key={idx}
                  style={{ display: currentIdx === idx ? "block" : "none" }}
                >
                  {w}
                </p>
              ))}
            </section>
            <section>
              <HeadShotComponent />
            </section>
            <section className="right-info">
              <p>
                As a creative and data-driven web developer with a background in
                music production, I bring a unique blend of artistry and
                technical expertise to every project. Utilizing an innovative
                approach, I create dynamic and engaging online experiences that
                inspire and connect people.
              </p>
            </section>
          </>
        );
      case "es":
        return (
          <>
            <section className="left-info">
              <h1>RICHARD MUNDY III</h1>
              {words.map((w, idx) => (
                <p
                  key={idx}
                  style={{ display: currentIdx === idx ? "block" : "none" }}
                >
                  {w}
                </p>
              ))}
            </section>
            <section>
              <HeadShotComponent />
            </section>
            <section className="right-info">
              <p>
                Como desarrollador web creativo y orientado a los datos con
                experiencia en producción musical, aporto una combinación única
                de talento artístico y experiencia técnica a cada proyecto. Con
                un enfoque innovador, creo experiencias en línea dinámicas y
                atractivas que inspiran y conectan a las personas.
              </p>
            </section>
          </>
        );
      case "vi":
        return (
          <>
            <section className="left-info">
              <h1>RICHARD MUNDY III</h1>
              {words.map((w, idx) => (
                <p
                  key={idx}
                  style={{ display: currentIdx === idx ? "block" : "none" }}
                >
                  {w}
                </p>
              ))}
            </section>
            <section>
              <HeadShotComponent />
            </section>
            <section className="right-info">
              <p>
                Là một người sáng tạo và có định hướng dữ liệu trong phát triển
                web với nền tảng là sản xuất âm nhạc, tôi mang đến sự kết hợp
                độc đáo giữa nghệ thuật và chuyên môn kỹ thuật cho mọi dự án. Sử
                dụng phương pháp tiếp cận sáng tạo, tôi tạo ra những trải nghiệm
                trực tuyến năng động và hấp dẫn, truyền cảm hứng và kết nối mọi
                người.
              </p>
            </section>
          </>
        );
    }
  }

  return <main className="home-container">{renderContext()}</main>;
}
