import React, { useState } from "react";
import { useLanguage } from "../../LanguageContext";
import VideoPoker from "../../assets/images/videoPoker.jpg";
import DartLeague from "../../assets/images/dartLeague.jpg";
import MickeyLifeCoach from "../../assets/images/MickeyLifeCoach.jpg";
import PAGC from "../../assets/images/PAGC.jpg";
import "./ProjectsPage.css";

export default function ProjectsPage() {
  const { language } = useLanguage();
  const [projectView, setProjectView] = useState(null);

  const langOptions = {
    en: {
      lifeCoach: {
        details:
          "My client Mickey reached out with the desire to connect with current and prospective clients. Using React and Material UI, I created a sleek and modern looking site to fulfill his desires. The site uses NodeJS on the backend to make CRUD API calls for a peronsal blog page and to send alert emails that a prospective new client is interested in a consulation. There is a custom admin page for Mickey to manage the blog posts, including a rich text editor, image upload to a S3 bucket, and create `sticky posts` that remain at the top to be viewed the most.",
        image: MickeyLifeCoach,
        gitHub: "https://github.com/richardmundyiii/mickey-the-life-coach",
        demo: "https://mickeythelifecoach.com",
      },
      dartLeague: {
        details:
          "The Santa Cruz Dart league reached out to me looking for a better option to manage their league stats. This site includes a weekly webscraper to collect data from their score keeping app's site, an admin portal to CRUD players, teams, scores, venues, events, and news. This was created using MERN (Mongo DB, Express, React, NodeJS) stack and Material UI. Currently working on a new release (2.0) for this site using a React frontend and Django backend that will also incorporate a scoring app to replace their current program. 2.0 will also include user created player profiles, and utilize Redux-Toolkit to manage state across the site.",
        image: DartLeague,
        gitHub: "https://github.com/richardmundyiii/dart-league",
        demo: "https://www.dartsofsantacruz.com",
      },
      paloAltoGolfClub: {
        details:
          "A revampd golf club and golf course needed a fresh new look. Allowing board memebers and dedicated webmaster to CRUD upcoming events, player standings, and event results. This site also integrates components from other USGA sites needed to comply with handicapping. Created using HTML, CSS, Bootstrap v5, and vanilla JS so that minimal coding knowledge would be needed for future webmasters.",
        image: PAGC,
        gitHub: "https://github.com/richardmundyiii/pagc",
        demo: "https://www.paloaltogolfclub.com",
      },
      videoPoker: {
        details:
          "A revampd golf club and golf course needed a fresh new look. Allowing board memebers and dedicated webmaster to CRUD upcoming events, player standings, and event results. This site also integrates components from other USGA sites needed to comply with handicapping. Created using HTML, CSS, Bootstrap v5, and vanilla JS so that minimal coding knowledge would be needed for future webmasters.",
        image: VideoPoker,
        gitHub: "http://www.github.com/richardmundyiii/",
        demo: "",
      },
    },
    es: {
      lifeCoach: {
        details:
          "Mi cliente Mickey se acercó con el deseo de conectarse con clientes actuales y potenciales. Usando React y Material UI, creé un sitio elegante y moderno para satisfacer sus deseos. El sitio usa NodeJS en el backend para realizar llamadas API CRUD para un página de blog personal y para enviar correos electrónicos de alerta de que un nuevo cliente potencial está interesado en una consulta. Hay una página de administración personalizada para que Mickey administre las publicaciones del blog, incluido un editor de texto enriquecido, carga de imágenes en un depósito S3 y creación de archivos adhesivos. publicaciones` que permanecen en la parte superior para ser más vistas.",
        image: MickeyLifeCoach,
        gitHub: "https://github.com/richardmundyiii/mickey-the-life-coach",
        demo: "https://mickeythelifecoach.com",
      },
      dartLeague: {
        details:
          "La liga Santa Cruz Dart se acercó a mí buscando una mejor opción para administrar las estadísticas de su liga. Este sitio incluye un webscraper semanal para recopilar datos del sitio de su aplicación de mantenimiento de puntajes, un portal de administración para jugadores, equipos, puntajes, lugares, eventos y noticias de CRUD. Esto se creó utilizando la pila MERN (Mongo DB, Express, React, NodeJS) y la interfaz de usuario Material. Actualmente estoy trabajando en una nueva versión (2.0) para este sitio usando una interfaz React y un backend Django que también incorporará una aplicación de puntuación para reemplazar su programa actual. 2.0 también incluirá perfiles de jugadores creados por los usuarios y utilizar Redux-Toolkit para gestionar el estado en todo el sitio.",
        image: DartLeague,
        gitHub: "https://github.com/richardmundyiii/dart-league",
        demo: "https://www.dartsofsantacruz.com",
      },
      paloAltoGolfClub: {
        details:
          "Un club y un campo de golf renovados necesitaban una nueva apariencia. Permitir a los miembros de la junta y al webmaster dedicado conocer los próximos eventos de CRUD, la clasificación de los jugadores y los resultados de los eventos. Este sitio también integra componentes de otros sitios de la USGA necesarios para cumplir con las normas de discapacidad. Creado utilizando HTML, CSS, Bootstrap v5 y Vanilla JS, por lo que los futuros webmasters necesitarían conocimientos mínimos de codificación.",
        image: PAGC,
        gitHub: "https://github.com/richardmundyiii/pagc",
        demo: "https://www.paloaltogolfclub.com",
      },
      videoPoker: {
        details: "",
        image: VideoPoker,
        gitHub: "",
        demo: "",
      },
    },
    vi: {
      lifeCoach: {
        details:
          "Khách hàng Mickey của tôi đến với mong muốn kết nối với khách hàng hiện tại và khách hàng tiềm năng. Bằng cách sử dụng React và Material UI, tôi đã tạo ra một trang web có giao diện đẹp mắt và hiện đại để đáp ứng mong muốn của anh ấy. Trang web sử dụng NodeJS ở phần phụ trợ để thực hiện các lệnh gọi API CRUD cho trang blog cá nhân và gửi email cảnh báo rằng một khách hàng mới tiềm năng quan tâm đến lãnh sự quán. Có một trang quản trị tùy chỉnh để Mickey quản lý các bài đăng trên blog, bao gồm trình soạn thảo văn bản đa dạng thức, tải hình ảnh lên nhóm S3 và tạo `sticky. các bài đăng` vẫn ở trên cùng để được xem nhiều nhất và sử dụng Redux-Toolkit để quản lý trạng thái trên trang web.",
        image: MickeyLifeCoach,
        gitHub: "https://github.com/richardmundyiii/mickey-the-life-coach",
        demo: "https://mickeythelifecoach.com",
      },
      dartLeague: {
        details:
          "Liên đoàn Santa Cruz Dart đã liên hệ với tôi để tìm kiếm một lựa chọn tốt hơn để quản lý số liệu thống kê giải đấu của họ. Trang web này bao gồm một công cụ thu thập dữ liệu web hàng tuần để thu thập dữ liệu từ trang web của ứng dụng lưu giữ điểm số của họ, một cổng quản trị dành cho người chơi, đội, điểm số, địa điểm, sự kiện và tin tức của CRUD. Điều này được tạo bằng cách sử dụng ngăn xếp MERN (Mongo DB, Express, React, NodeJS) và Material UI. Hiện đang làm việc trên một bản phát hành mới (2.0) cho trang web này bằng cách sử dụng giao diện người dùng React và phụ trợ Django cũng sẽ kết hợp một ứng dụng tính điểm để thay thế chương trình hiện tại của họ. 2.0 cũng sẽ bao gồm hồ sơ người chơi do người dùng tạo.",
        image: DartLeague,
        gitHub: "https://github.com/richardmundyiii/dart-league",
        demo: "https://www.dartsofsantacruz.com",
      },
      paloAltoGolfClub: {
        details:
          "Câu lạc bộ gôn và sân gôn đã được tân trang lại cần một diện mạo mới. Cho phép các thành viên hội đồng quản trị và quản trị viên web chuyên dụng tham gia các sự kiện sắp tới của CRUD, bảng xếp hạng người chơi và kết quả sự kiện. Trang web này cũng tích hợp các thành phần từ các trang USGA khác cần thiết để tuân thủ handicap. Được tạo bằng HTML, CSS, Bootstrap v5 và vanilla JS để các quản trị viên web trong tương lai cần có kiến ​​thức mã hóa tối thiểu.",
        image: PAGC,
        gitHub: "https://github.com/richardmundyiii/pagc",
        demo: "https://www.paloaltogolfclub.com",
      },
      videoPoker: {
        details: "",
        image: VideoPoker,
        gitHub: "",
        demo: "",
      },
    },
  };

  function handleClickView(evt) {
    setProjectView(evt.target.getAttribute("name"));
  }

  const currentLanguage = langOptions[language];

  return (
    <>
      <div className="project-page-container">
        <div className="project-view-container">
          {projectView && (
            <div>
              <img
                src={langOptions[language][projectView].image}
                alt={projectView.alt}
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <p>{langOptions[language][projectView].details}</p>
              <a href={langOptions[language][projectView].gitHub}>
                <button>GitHub</button>
              </a>
              <a href={langOptions[language][projectView].demo}>
                <button>Demo</button>
              </a>
            </div>
          )}
        </div>
        <div className="project-list-container">
          <ul>
            <li name="lifeCoach" onClick={handleClickView}>
              Life Coach
            </li>
            <li name="dartLeague" onClick={handleClickView}>
              Dart League
            </li>
            <li name="paloAltoGolfClub" onClick={handleClickView}>
              Palo Alto Golf Club
            </li>
            <li name="videoPoker" onClick={handleClickView}>
              Video Poker
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile View */}
      <div className="project-page-container-mobile">
        <div className="project-card">
          <img src={MickeyLifeCoach} alt="..." />
          <p>{currentLanguage.lifeCoach.details}</p>
        </div>
        <div className="project-card">
          <img src={DartLeague} alt="..." />
          <p>{currentLanguage.dartLeague.details}</p>
        </div>
        <div className="project-card">
          <img src={PAGC} alt="..." />
          <p>{currentLanguage.paloAltoGolfClub.details}</p>
        </div>
      </div>
    </>
  );
}
