import { useState } from "react";
import { Link } from "react-router-dom";
import "./Contact.css";
import GithubLogo from "../../assets/images/technologies/github.svg";
import LinkedInLogo from "../../assets/images/technologies/linkedin.svg";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();
    for (const key in formData) {
      form.append(key, formData[key]);
    }

    emailjs
      .sendForm(
        "service_346exv5",
        "template_yi36t3h",
        form,
        "6KtkWUgxfVuDr0UeU"
      )
      .then(
        (result) => {
          setFormData({ name: "", email: "", message: "" });
          alert("Email sent successfully");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <main className="contact-container">
        <h1>Contact Me</h1>
        <section className="form-card">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <button className="btn" type="submit">
              Submit
            </button>
          </form>
          <section className="contact-socials">
            <Link to="https://github.com/richardmundyiii" target="_blank">
              <img src={GithubLogo} alt="Github Logo" />
            </Link>
            <Link
              to="https://www.linkedin.com/in/richardmundy3"
              target="_blank"
            >
              <img src={LinkedInLogo} alt="LinkedIn Logo" />
            </Link>
          </section>
        </section>
      </main>
    </>
  );
}
