import { useLanguage } from "../../LanguageContext";
import AboutMeImage from "../../assets/images/aboutmeImage.png";
import PromoVideo from "../../assets/vids/DJRichMundyPromo.mp4";
import HTMLLogo from "../../assets/images/technologies/html5.svg";
import CSS3 from "../../assets/images/technologies/css3.svg";
import BootstrapLogo from "../../assets/images/technologies/bootstrap.svg";
import ChakraUI from "../../assets/images/technologies/chakra.png";
import MUILogo from "../../assets/images/technologies/MUI.svg";
import TailwindLogo from "../../assets/images/technologies/tailwindcss.svg";
import JSLogo from "../../assets/images/technologies/javascript.svg";
import PythonLogo from "../../assets/images/technologies/python.svg";
import ReactLogo from "../../assets/images/technologies/react.svg";
import DjangoLogo from "../../assets/images/technologies/django.svg";
import NodeJS from "../../assets/images/technologies/nodejs.svg";
import MongoDB from "../../assets/images/technologies/mongodb.svg";
import MySQL from "../../assets/images/technologies/mysql.svg";
import PostgreSQL from "../../assets/images/technologies/postgresql.svg";
import LinkedInLogo from "../../assets/images/technologies/linkedin.svg";
import GitHubLogo from "../../assets/images/technologies/github.svg";
import ExpressJS from "../../assets/images/technologies/express.png";
import "./AboutPage.css";

export default function About() {
  const { language } = useLanguage();

  const langOptions = {
    en: {
      name: "Hi, I'm Richard",
      adjectives: "Developer.Designer.Problem Solver",
      about: [
        "I was raised in the Silicon Valley and have a unique and diverse background. Before diving into the world of technology, I spent over 15 years as a touring DJ, which allowed me to explore my passion for music and connect with people from all walks of life.",
        "I am trilingual, fluent in English and Vietnamese, and proficient in Spanish. I've had the incredible opportunity to live in 5 different countries (US, Vietnam, Japan, Macau, and Colombia) and have traveled to 87 countries. These experiences have not only broadened my cultural horizons but also fueled my love for learning and personal growth.",
        "As a software engineer, I thrive on solving complex problems and continuously expanding my knowledge. Outside of coding, I'm deeply fascinated by aviation, enjoy playing sports, and have recently taken up pickleball. I believe that the best way to grow is by embracing new challenges and learning from every experience. I'm excited to bring my diverse skill set and global perspective to every project I undertake.",
      ],
      experience: {
        title: "Experience",
        items: [
          "- 15 years touring DJ",
          "- 5 years Event Planning / Management",
          "- 4 years Web Development",
          "- 2 years Software Developement",
          "- 20+ years Living Life to the Fullest",
        ],
      },
      skills: {
        title: "Skills",
        items: [JSLogo, PythonLogo],
      },
    },
    es: {
      name: "Soy Richard",
      adjectives: "Desarrollador.Diseñador.Resuelve Problemas",
      about: [
        "Crecí en Silicon Valley y tengo una experiencia única y diversa. Antes de trabajar en el mundo de la tecnología, pasé más de 15 años como DJ de gira, lo que me permitió explorar mi pasión por la música y conectarme con personas de todos los ámbitos de la vida.",
        "Soy trilingüe, hablo inglés y vietnamita con fluidez y domino el español. He tenido la increíble oportunidad de vivir en 5 países diferentes (Estados Unidos, Vietnam, Japón, Macao y Colombia) y he viajado a 87 países. Estas experiencias no sólo han ampliado mis horizontes culturales sino que también han alimentado mi amor por el aprendizaje y el crecimiento personal.",
        "Como ingeniero de software, disfruto resolviendo problemas complejos y ampliando continuamente mis conocimientos. Aparte de la programación, estoy profundamente fascinado por la aviación, disfruto practicando deportes y recientemente he practicado pickleball. Creo que la mejor manera de crecer es aceptando nuevos desafíos y aprendiendo de cada experiencia. Me entusiasma aportar mi conjunto diverso de habilidades y mi perspectiva global a cada proyecto que emprendo.",
      ],
      experience: {
        title: "Experiencia",
        items: ["", "", ""],
      },
    },
    vi: {
      name: "Tôi là Richard",
      adjectives: "Nhà Phát Triển.Nhà Thiết Kế. Người giải quyết vấn đề",
      about: [
        "Tôi lớn lên ở Silicon Valley và có nền tảng độc đáo và đa dạng. Trước khi bước chân vào thế giới công nghệ, tôi đã có hơn 15 năm làm DJ lưu diễn, điều này cho phép tôi khám phá niềm đam mê âm nhạc của mình và kết nối với mọi người từ mọi tầng lớp xã hội.",
        "Tôi nói được ba thứ tiếng, thông thạo tiếng Anh và tiếng Việt và thông thạo tiếng Tây Ban Nha. Tôi đã có cơ hội tuyệt vời để sống ở 5 quốc gia khác nhau (Mỹ, Việt Nam, Nhật Bản, Ma Cao và Colombia) và đã đi đến 87 quốc gia. Những trải nghiệm này không chỉ mở rộng tầm nhìn văn hóa của tôi mà còn thúc đẩy niềm yêu thích học tập và phát triển cá nhân của tôi.",
        "Là một kỹ sư phần mềm, tôi phát triển mạnh trong việc giải quyết các vấn đề phức tạp và liên tục mở rộng kiến thức của mình. Ngoài việc viết mã, tôi còn say mê ngành hàng không, thích chơi thể thao và gần đây đã chơi môn bóng ném. Tôi tin rằng cách tốt nhất để phát triển là đón nhận những thử thách mới và học hỏi từ mọi trải nghiệm. Tôi rất vui được áp dụng bộ kỹ năng đa dạng và quan điểm toàn cầu của mình vào mọi dự án tôi thực hiện.",
      ],
      experience: {
        title: "Kinh Nghiệm",
        items: ["", "", ""],
      },
    },
  };

  const designTech = [
    { name: "HTML", src: HTMLLogo, alt: "HTML" },
    { name: "CSS3", src: CSS3, alt: "CSS3" },
    { name: "Bootstrap", src: BootstrapLogo, alt: "Bootstrap" },
    { name: "ChakraUI", src: ChakraUI, alt: "ChakraUI" },
    { name: "TailwindCSS", src: TailwindLogo, alt: "Tailwind CSS" },
    {
      name: "Material UI",
      src: MUILogo,
      alt: "Material UI",
      maxwidth: "3.5rem",
    },
  ];

  const backEndTech = [
    { name: "NodeJS", src: NodeJS, alt: "NodeJS" },
    { name: "Django", src: DjangoLogo, alt: "Django", maxwidth: "3rem" },
    { name: "Express", src: ExpressJS, alt: "Express" },
  ];

  const databases = [
    { name: "MongoDB", src: MongoDB, alt: "Mongo DB", maxHeight: "80rem" },
    { name: "MySQL", src: MySQL, alt: "MySQL" },
    { name: "PostgreSQL", src: PostgreSQL, alt: "PostgreSQL" },
  ];

  const techLanguages = [
    { name: "Javascript", src: JSLogo, alt: "Javascript" },
    { name: "Python", src: PythonLogo, alt: "Python" },
  ];

  const currentLang = langOptions[language];
  const restAbout = currentLang.about.slice(1);
  const experience = currentLang.experience;

  return (
    <>
      <main className="about-me-wrapper">
        <div className="about-header">
          <h1>{currentLang.name}</h1>
          <img src={AboutMeImage} alt="Profile photo" />
        </div>
        <div className="about-bio">
          {currentLang.about.map((item, idx) => (
            <p key={idx}>{item}</p>
          ))}
          <h3>{experience.title}</h3>
          <ul>
            {experience.items.map((item, idx) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
        <div>
          <div>
            <video
              width="45%"
              controls
              style={{ marginLeft: "3rem", float: "left" }}
            >
              <source src={PromoVideo} />
            </video>
          </div>
          <div className="tech-images">
            <div>
              {techLanguages.map((item, idx) => (
                <img src={item.src} alt={item.alt} key={idx} />
              ))}
            </div>
            <div>
              {designTech.map((item, idx) => (
                <img src={item.src} alt={item.alt} key={idx} />
              ))}
            </div>
            <div>
              {backEndTech.map((item, idx) => (
                <img src={item.src} alt={item.alt} key={idx} />
              ))}
            </div>
            <div>
              {databases.map((item, idx) => (
                <img
                  src={item.src}
                  alt={item.alt}
                  key={idx}
                  height={item.maxHeight}
                />
              ))}
            </div>
          </div>
        </div>
      </main>

      {/* Mobile View */}
      <main className="about-me-wrapper-mobile">
        <section className="about-me-header-mobile">
          <span className="header-text">{currentLang.name}</span>
          <img src={AboutMeImage} alt="about me profile" />
        </section>
        <section className="about-me-bio-mobile">
          <h2 style={{ textAlign: "right" }}>About Me</h2>
          <p>{currentLang.about[0]}</p>
          <video width="100%" controls>
            <source src={PromoVideo} />
          </video>
          {restAbout.map((p, idx) => (
            <p key={idx}>{p}</p>
          ))}
        </section>
        <div className="expertise-wrapper">
          <h2 style={{ textAlign: "right", padding: "0 1.5rem 0 1.5rem" }}>
            Expertise
          </h2>
          <h6 className="expertise-headings">Design</h6>
          <section className="about-me-design-mobile">
            {designTech.map((item, idx) => (
              <div key={idx}>
                <img
                  src={item.src}
                  alt={item.alt}
                  style={{ width: item.maxwidth }}
                />
              </div>
            ))}
          </section>
          <h6 className="expertise-headings">Databases</h6>
          <section className="about-me-databases">
            {databases.map((item, idx) => (
              <div key={idx}>
                <img
                  src={item.src}
                  alt={item.alt}
                  style={{ width: item.maxwidth }}
                />
              </div>
            ))}
          </section>
          <h6 className="expertise-headings">Back-End</h6>
          <section className="about-me-backend">
            {backEndTech.map((item, idx) => (
              <div key={idx}>
                <img src={item.src} alt={item.alt} />
              </div>
            ))}
          </section>
        </div>
      </main>
    </>
  );
}
