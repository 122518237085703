import { Link } from "react-router-dom";
import "./Footer.css";
import GitHubLogo from "../../assets/images/technologies/github.svg";
import LinkedInLogo from "../../assets/images/technologies/linkedin.svg";
import { useLanguage } from "../../LanguageContext";

export default function Footer({ darkMode }) {
  const { language } = useLanguage();

  function renderContext() {
    switch (language) {
      case "en":
        return "Full-Stack Software Engineer";
      case "es":
        return "Ingeniero de Software de Pila Completa";
      case "vi":
        return "Kỹ sư phần mềm Full Stack";
    }
  }
  return (
    <>
      <main
        className={`footer-container ${
          darkMode ? "footer-container-dark-mode" : ""
        }`}
      >
        <section className="footer-left">
          <Link to="http://www.github.com/richardmundyiii">
            <img
              src={GitHubLogo}
              alt="Github Logo"
              className={darkMode ? "footer-container-dark" : ""}
            />
          </Link>
        </section>
        <section className="footer-center">
          Richard Mundy III {renderContext()}
        </section>
        <section className="footer-right">
          <Link to="http://www.linkedin.com/in/richardmundy3">
            <img
              src={LinkedInLogo}
              alt="LinkedIn Logo"
              className={darkMode ? "footer-container-dark-mode" : ""}
            />
          </Link>
        </section>
      </main>
      <main
        className={`footer-container-mobile ${
          darkMode ? "footer-container-dark-mode" : ""
        }`}
      >
        <section className="footer-center-mobile">
          Richard Mundy III {renderContext()}
        </section>
        <section className="footer-right-mobile">
          <Link to="http://www.linkedin.com/in/richardmundy3">
            <img
              src={LinkedInLogo}
              alt="LinkedIn Logo"
              className={darkMode ? "footer-container-dark-mode" : ""}
            />
          </Link>
          <Link to="http://www.github.com/richardmundyiii">
            <img
              src={GitHubLogo}
              alt="Github Logo"
              className={darkMode ? "footer-container-dark" : ""}
            />
          </Link>
        </section>
      </main>
    </>
  );
}
