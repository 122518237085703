import { useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../LanguageContext";
import "./NavBar.css";

export default function NavBar({ toggleDarkMode, darkMode }) {
  const { language, toggleLanguage } = useLanguage();
  const [showLanauges, setShowLanguages] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  function handleShowLanguages() {
    setShowLanguages(!showLanauges);
  }

  function handleLanguageChange(evt) {
    toggleLanguage(evt.target.value);
    setShowLanguages(false);
  }

  function renderContext() {
    switch (language) {
      case "en":
        return (
          <>
            <nav className="navbar">
              <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-1" : "bar"}
                ></span>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-2" : "bar"}
                ></span>
              </div>
              <div
                className={
                  mobileMenuOpen ? "navbar-links show" : "navbar-links"
                }
              >
                <Link to="/" onClick={toggleMobileMenu}>
                  Home
                </Link>
                <Link to="/about" onClick={toggleMobileMenu}>
                  About
                </Link>
                <Link to="/projects" onClick={toggleMobileMenu}>
                  Projects
                </Link>
                <Link to="/contact" onClick={toggleMobileMenu}>
                  Contact
                </Link>
                <a
                  href={`${process.env.PUBLIC_URL}/RichardMundyResume.pdf`}
                  onClick={toggleMobileMenu}
                >
                  Resume
                </a>
                <div className="theme-switch-wrapper">
                  <label className="theme-switch">
                    <input
                      onClick={toggleDarkMode}
                      type="checkbox"
                      id="checkbox"
                    />
                    <div className="slider round"></div>
                  </label>
                  {darkMode ? <em>Dark Mode</em> : <em>Light Mode</em>}
                </div>

                {/* Language Dropdown  */}
                <div className="language-drop-wrapper">
                  <label className="language-drop">
                    <span
                      className="fi fi-us"
                      style={{ marginRight: "0.25rem" }}
                    ></span>
                    <select value={language} onChange={handleLanguageChange}>
                      <option value="en">English</option>
                      <option value="es">Spanish</option>
                      <option value="vi">Vietnamese</option>
                    </select>
                  </label>
                </div>
              </div>
            </nav>
          </>
        );
      case "es":
        return (
          <>
            <nav className="navbar">
              <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-1" : "bar"}
                ></span>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-2" : "bar"}
                ></span>
              </div>
              <div
                className={
                  mobileMenuOpen ? "navbar-links show" : "navbar-links"
                }
              >
                <Link to="/" onClick={toggleMobileMenu}>
                  Inicio
                </Link>
                <Link to="/about" onClick={toggleMobileMenu}>
                  Acerca De
                </Link>
                <Link to="/projects" onClick={toggleMobileMenu}>
                  Proyectos
                </Link>
                <Link to="/contact" onClick={toggleMobileMenu}>
                  Contáctame
                </Link>
                <a
                  href={`${process.env.PUBLIC_URL}/RichardMundyResume.pdf`}
                  onClick={toggleMobileMenu}
                >
                  Resume
                </a>
                <div className="theme-switch-wrapper">
                  <label className="theme-switch">
                    <input
                      onClick={toggleDarkMode}
                      type="checkbox"
                      id="checkbox"
                    />
                    <div className="slider round"></div>
                  </label>
                  {darkMode ? <em>Dark Mode</em> : <em>Light Mode</em>}
                </div>

                {/* Language Dropdown  */}
                <div className="language-drop-wrapper">
                  <label className="language-drop">
                    <span
                      className="fi fi-es"
                      style={{ marginRight: "0.25rem" }}
                    ></span>
                    <select value={language} onChange={handleLanguageChange}>
                      <option value="en">Inglés</option>
                      <option value="es">Español</option>
                      <option value="vi">Vietnamita</option>
                    </select>
                  </label>
                </div>
              </div>
            </nav>
          </>
        );
      case "vi":
        return (
          <>
            <nav className="navbar">
              <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-1" : "bar"}
                ></span>
                <span
                  className={mobileMenuOpen ? "bar bar-rotate-2" : "bar"}
                ></span>
              </div>
              <div
                className={
                  mobileMenuOpen ? "navbar-links show" : "navbar-links"
                }
              >
                <Link to="/" onClick={toggleMobileMenu}>
                  Trang chủ
                </Link>
                <Link to="/about" onClick={toggleMobileMenu}>
                  Về tôi
                </Link>
                <Link to="/projects" onClick={toggleMobileMenu}>
                  Dự án
                </Link>
                <Link to="/contact" onClick={toggleMobileMenu}>
                  Liên hệ
                </Link>
                <a
                  href={`${process.env.PUBLIC_URL}/RichardMundyResume.pdf`}
                  onClick={toggleMobileMenu}
                >
                  CV
                </a>
                <div className="theme-switch-wrapper">
                  <label className="theme-switch">
                    <input
                      onClick={toggleDarkMode}
                      type="checkbox"
                      id="checkbox"
                    />
                    <div className="slider round"></div>
                  </label>
                  {darkMode ? <em>Chế độ tối</em> : <em>Chế độ sáng</em>}
                </div>

                {/* Language Dropdown  */}
                <div className="language-drop-wrapper">
                  <label className="language-drop">
                    <span
                      className="fi fi-vn"
                      style={{ marginRight: "0.25rem" }}
                    ></span>
                    <select value={language} onChange={handleLanguageChange}>
                      <option value="en">Tiếng Anh</option>
                      <option value="es">Tiếng Tây Ban Nha</option>
                      <option value="vi">Tiếng Việt</option>
                    </select>
                  </label>
                </div>
              </div>
            </nav>
          </>
        );
    }
  }

  return <>{renderContext()}</>;
}
