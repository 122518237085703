import ProfileImg from "../../assets/images/profile.svg";
import "./HeadShotComponent.css";

export default function HeadShotComponent() {
  return (
    <section className="image-section">
      <div className="headshot-image-container">
        <img src={ProfileImg} alt="Richard Mundy's Headshot" />
      </div>
    </section>
  );
}
